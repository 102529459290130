<template>
  <div>
    <span ref="propTop"></span>
    <div v-if="prop && prop.name">
      <v-card class="pt-2 px-3 pb-3 mb-6">
        <h2>
          <span class="grey--text capitalize">
            {{ prop.type == 'item' ? $t('Prop') : $t(prop.type) }}:
          </span>
          {{ prop.name[$i18n.locale] }}
          <v-chip dense small class="absolute right mr-2">
            {{ Math.max((prop.qrcodes_of_serial_numbers.split(",")).length, prop.current_quantity_excl_cases, prop.current_quantity) }}<!-- 
            -->{{ $t('pcs') }}
          </v-chip>
        </h2>

        <hr class="mb-4 op-12">
        
        <div>
          <Favorite
            :propId="propId"
            buttonClasses="mr-2"
            :showListName="true"
          ></Favorite>

          <Share
            buttonColor="white"
            opacity="1"
            buttonClasses="primary mr-2"
            :text="`${mainDomain}/#/db/${this.prop.idPath.join('/')}/${propId}.html`"
            :tooltip="$t('Share this prop')"
          ></Share>
          <a
            :href="`https://funduscatourne.rentmanapp.com/#/equipment/${prop['id']}`"
            target="_blank"
            v-if="user.role === 'supervisor' || user.role === 'admin'"
            title="Direct Rentman link"
          >
            <v-btn icon class="white--text" small style="background-color: #ff6729;">
              <v-icon small>mdi-rentman</v-icon>
            </v-btn>
          </a>
        </div>
      </v-card>

      <div v-if="prop.set_items && prop.set_items.length">
        <h3 class="mt-6">
          <v-icon class="primary--text">mdi-set-all</v-icon>
          {{  `${$t('This is a set of')} ${prop.set_items.length} ${$t('props')}` }}
        </h3>
        <!-- <code>set_items: {{ prop.set_items }}</code><br>
        <code>is_part_of_set: {{ prop.is_part_of_set }}</code><br>
        <code>set_parent_code: {{ prop.set_parent_code }}</code><br>-->

        <Proplist
          v-for="propId in prop.set_items"
          :user="user"
          :listView="$vuetify.breakpoint.xsOnly"
          :key="propId"
          :propIndex="propId"
          :propId="propId"
          :cardSize="222"
          cardClasses="mb-4"
          :categories="[]"
          @clicked="scrollToLocal('propTop')"
        ></Proplist>
        <!-- 
          :cardSize="`${100/prop.set_items.length}%`"
        -->
        <!-- 
          :listView="listView || $vuetify.breakpoint.xs"
        -->

      </div>

      <!-- <v-hover
        v-slot="{ hover }"
        v-if="prop.poster_file && $helpers.imageChooser(prop.poster_file, 'large')"
      >
        <v-img
          aspect-ratio="1.777"
          :contain="!hover"
          :cover="hover"
          class="grey my-2"
          :class="$vuetify.theme.dark ? 'darken-4' : 'lighten-2'"
          :lazy-src="`props/${propId}/files/${$helpers.imageChooser(prop.poster_file, 'small')}`"
          :src="`props/${propId}/files/${$helpers.imageChooser(prop.poster_file, 'large')}`"
          alt="cannot load image"
        />
      </v-hover> -->

      <!-- <h3>
        <v-icon small class="primary--text">mdi-image-multiple</v-icon>
        {{ $t('Gallery') }}
      </h3> -->
      <!--
        Hide gallery if this is a set of other items with only one or zero images
        as the other items are displayed above
      -->
      <Gallery
        v-if="!(prop.set_items && prop.set_items.length && files.filter(file => file.data.type.includes('image')).length <= 1)"
        :propId="propId"
        :aspectRatio="$vuetify.breakpoint.smAndDown ? '0.5625' : '1.777'"
        :showThumbnails="$vuetify.breakpoint.smAndUp && Object.keys(files).length > 1"
        :windowed="false"
        :showNameThumbnail="false"
        thumbnailContainerClasses="pt-2 text--center"
        thumbnailClasses="mr-2 mb-2"
        rounded
        subfolder="files"
        :images="files.filter(file => file.data.type.includes('image'))"
        cover
        width="120px"
        height="120px"
      ></Gallery>

      <!-- poster_file: <code>{{ prop.poster_file }}</code><br>
      small: <code>{{ `props/${propId}/files/${$helpers.imageChooser(prop.poster_file, 'small')}` }}</code><br>
      large: <code>{{ `props/${propId}/files/${$helpers.imageChooser(prop.poster_file, 'large')}` }}</code><br>
      <hr> -->

      <h3 class="mt-6">
        <v-icon class="primary--text">mdi-information-slab-box</v-icon>
        {{ $t('Info') }}
      </h3>
      <ul class="noList pa-0">
        <!-- custom_1 is "artikelbezeichnung in englsih which is not useful anymore -->
        <li class="mt-1"
          v-for="(custom_value, index) in Object.values(prop.custom).filter(custom_value => custom_value.value[$i18n.locale].length > 0)"
          :style="rentmanFieldId(prop, 'custom_1', index) ? 'display:none' : ''"
          :key="$helpers.createUid()+custom_value['name']['de']"
        >
          <div class="inlineBlock grey--text text--vert-top" style="min-width: 150px;">
            {{ custom_value["name"][$i18n.locale] }}
          </div>
          <div class="inlineBlock text--vert-top ml-2 pb-1" v-html="custom_value['value'][$i18n.locale].replace(';', '<br>')"></div>
        </li>
        <li class="mt-1" v-if="((prop.height > 0) + (prop.width > 0) + (prop.length > 0)) > 0">
          <div class="inlineBlock grey--text text--vert-top" style="min-width: 150px;">{{ $t('Dimensions') }}</div>
          <div class="inlineBlock text--vert-top ml-2 pb-1">
            <v-icon small class="grey--text mr-1" v-if="((prop.height > 0) + (prop.width > 0) + (prop.length > 0)) > 2">mdi-axis-arrow</v-icon>
            <v-icon small class="grey--text mr-1" v-else-if="((prop.height > 0) + (prop.width > 0) + (prop.length > 0)) > 1">mdi-set-square</v-icon>
            <v-icon small class="grey--text mr-1" v-else-if="((prop.height > 0) + (prop.width > 0) + (prop.length > 0)) > 0">mdi-ruler</v-icon>
            <span v-html="dimensionsString"></span>
          </div>
        </li>
        <li class="mt-1" v-if="prop.price && (user.role === 'supervisor' || user.role === 'admin')">
          <div class="inlineBlock grey--text text--vert-top" style="min-width: 150px;">{{ $t('Price') }}</div>
          <div class="inlineBlock text--vert-top ml-2 pb-1">CHF {{ prop.price.toFixed(2) }}</div>
        </li>
        <!-- 
          <v-icon small class="primary--text">mdi-pencil-box</v-icon>
          <v-icon small class="primary--text">mdi-cash-100</v-icon>
          <span v-html="dimensionsString"></span>
         -->
      </ul>
      <br>

      <v-card
        v-if="hasTags(prop.tags, ['5ccac60607b102a6a4143a7c06169429', 'a2fdff6447d80e6ea6aa1fda67a35337', '1038def6221874fb7208dff6349c21ef']).length > 0"
        class="pa-2 pb-4"
      >
        <h4 class="grey--text mb-2">
          <v-icon class="secondary--text">mdi-alert-circle</v-icon>
          {{ $t('Notice') }}:
          <span class="capitalize italics white--text">
            "{{ hasTags(prop.tags, ['5ccac60607b102a6a4143a7c06169429', 'a2fdff6447d80e6ea6aa1fda67a35337', '1038def6221874fb7208dff6349c21ef']).join('", "') }}"
          </span>
        </h4>
        <div 
          v-html="$t('This is a generic prop.<br>We have this, but its not uniquely collected in our database and is not pointing to a specific one.<br>So, stop by and see what we have in store!')"
          class="ml-sm-7"
        >
        </div>
      </v-card>

      <h3 class="mt-6">
        <v-icon class="primary--text">mdi-tag-multiple</v-icon>
        Tags
      </h3>
        <v-chip
          v-for="(tag, hash) in prop.tags"
          :key="hash"
          class="capitalize black--text mr-2"
          small
          :color="tag.color"
        > 
        <!-- :key="$helpers.createUid()+tag['name']['de']" -->
        {{ tag.tag_category[$i18n.locale] }}:
        {{ tag.name[$i18n.locale] }}
      </v-chip>
      <br>
      <div v-if="(prop.qrcodes || prop.qrcodes_of_serial_numbers) && (user.role === 'supervisor' || user.role === 'admin')">
        <h3 class="mt-6">
          <v-icon class="primary--text">mdi-qrcode</v-icon>
          QR codes
          <a
            :href="`https://funduscatourne.rentmanapp.com/#/equipment/${prop['id']}/item/list?assetlocations=null`"
            target="_blank"
            title="Direct Rentman link to serialized numbers"
          >
            <v-btn icon class="white--text" small style="background-color: #ff6729;">
              <v-icon small>mdi-rentman</v-icon>
            </v-btn>
          </a>
        </h3>

        <Gallery
          :propId="propId"
          :aspectRatio="$vuetify.breakpoint.smAndDown ? '0.5625' : '1.777'"
          :windowed="true"
          :showThumbnails="true"
          :showNameThumbnail="true"
          thumbnailContainerClasses="pa-2"
          thumbnailClasses="mr-2 mb-2 code bold"
          imageClasses="white"
          rounded
          subfolder="qr"
          :imagePaths="[
            ...(prop.qrcodes && prop.qrcodes.length ? prop.qrcodes.split(',').map(qrcode => `${propId}/qr/${qrcode}.svg`) : []),
            ...(prop.qrcodes_of_serial_numbers && prop.qrcodes_of_serial_numbers.length 
                ? prop.qrcodes_of_serial_numbers.split(',').map(qrcode_of_serial_number => `${propId}/qr/${qrcode_of_serial_number}-serial.svg`) 
                : [])
          ].filter(item => item)"
          cover
          width="120px"
          height="120px"
        ></Gallery>
      </div>

      <!-- <code class="block" style="white-space:pre;  max-height: 420px; overflow: auto;">{{ prop }}</code> -->

      <br>
      <div v-if="files.length">
        <!-- <h3>Poster (biggest image in list):</h3>
        <code>{{ prop.poster_file[prop.poster_file.length-1] }}</code> -->
        <!-- {{ `../props/${propId}/files/${prop.poster_file}` }} -->
        <!-- Display other prop files here -->
        <div v-if="files.filter(file => !file.data.type.includes('image')).length">
          <h2><v-icon small class="primary--text">mdi-file-document-multiple</v-icon>
            {{ $t('Non-image files') }} ({{ files.filter(file => !file.data.type.includes('image')).length }})</h2>
          <ul class="noList pa-0">
            <li
              v-for="(file, fileIndex) in files.filter(file => !file.data.type.includes('image'))"
              :key="fileIndex"
              class="hoverList"
            >
              <a
                :href="`props/${propId}/files/${file.filename}`"
                target="blank"
                class="py-1 px-2 block"
              >
                {{ fileIndex+1 }}.
                <v-icon small class="primary--text">
                  mdi-{{ ({"pdf": "file-pdf-box", "xlsx": "table-large", "xls": "table-large", "mov": "file-video", "mp4": "file-video"}[file.data.extension.toLowerCase()] || "file") }}
                </v-icon>
                {{ file.data.name_without_extension }}<!-- 
                --><span class="grey--text">.{{ file.data.extension }}
                  -
                  {{ $t("Edited") }}: {{ $moment(file.data.modified).format("DD.MM.YY - HH:mm") }}
                  -
                  {{ $helpers.humanSize(file.data.size)[0] }}<!-- 
                  -->{{ $helpers.humanSize(file.data.size)[1] }}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="prop.is_part_of_set">
          <h3 class="mt-6">
            <v-icon class="primary--text">mdi-set-center</v-icon>
            {{ $t('This prop is part of a set') }}
          </h3>
          <!-- <code>set_items: {{ prop.set_items }}</code><br>
          <code>is_part_of_set: {{ prop.is_part_of_set }}</code><br>
          <code>set_parent_code: {{ prop.set_parent_code }}</code><br> -->

          <Proplist
            :user="user"
            :listView="$vuetify.breakpoint.xsOnly"
            :propIndex="prop.set_parent_code"
            :propId="prop.set_parent_code"
            :cardSize="222"
            cardClasses="mb-4"
            :categories="[]"
            @clicked="scrollToLocal('propTop')"
          ></Proplist>
        </div>
      </div>

      <div class="small grey--text">
        {{ $t('Last modified') }}
        <span class="italics">{{ $helpers.timeRelativeToNow($moment(prop.modified)) }}</span>
      </div>
      <!-- <code class="block" style="white-space:pre;  max-height: 420px; overflow: auto;">{{ files }}</code> -->
    </div>
    <div v-else-if="loading" class="text--center pt-16">
      <Loader classes="mt-16" :interval="444"></Loader>
    </div>
    <div v-else class="text--center pt-16">
      <span class="grey--text">
        <v-icon x-large class="op-12 my-4 shake">mdi-emoticon-confused</v-icon>
        <br>
        {{ $t('This prop does not exist or is archived.') }}
        <br>
        {{  $t('Prop ID') }}: <code> {{ propId }} </code>
        <br>
        <a
          :href="`https://funduscatourne.rentmanapp.com/#/equipment/?search=${propId}-inactive`"
          target="_blank"
          v-if="user.role === 'supervisor' || user.role === 'admin'"
          title="Direct Rentman link"
        >
          <v-btn icon class="mt-2 white--text" small style="background-color: #ff6729;">
            <v-icon small>mdi-rentman</v-icon>
          </v-btn>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import Breadcrumbs from "../components/Breadcrumbs.vue"
import Favorite from "../components/Favorite.vue"
import Gallery from "../components/Gallery.vue"
import Share from '@/components/Share'
import Proplist from "../components/Proplist.vue";
import Loader from "../components/Loader.vue"

  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      propId: Number,
      // prop: Object,
      // files: Array,  // this sucks. get the whole bunch ehre.
    },
    components: {
      // Breadcrumbs,
      Favorite,
      Share,
      Gallery,
      Proplist,
      Loader,
    },
    data() {
      return {
        originalTitle: '',
        loading: true,
        prop: {},
        /* "custom": {
          "custom_1": {name: "Artikelbezeichnung in Englisch", values: []},
          "custom_4": {name: "Bemerkung an Mieter*in", values: []},
          "custom_8": {name: "Wertegruppe?", values: ["Verbrauchsmaterial", "Darf verändert werden", "Perle", "Vorlage", "In Absprache Veränderbar", "Darf nicht verändert werden"]},
          "custom_14": {name: "Baujahr",values: ["Antik","1900", "1910", "1920", "1930", "1940", "1950", "1960", "1970", "1980", "1990", "2000", "2010", "2020", "Keine Angabe"]},
          "custom_15": {name: "Farbe 1", values: ["Schwarz", "Weiss", "Braun", "Silber", "Gold", "Bronze", "Grau", "Rot", "Orange", "Gelb", "Grün", "Tyrkis", "Blau", "Violett", "Pink", "Rosa", "Bunt", "keine Angabe", "Beige", "Anthrazit"]},
          "custom_16": {name: "Farbe 2", values: ["Schwarz", "Weiss", "Braun", "Silber", "Gold", "Bronze", "Grau", "Rot", "Orange", "Gelb", "Grün", "Tyrkis", "Blau", "Violett", "Pink", "Rosa", "Bunt", "keine Angabe", "Beige", "Anthrazit"]},
          "custom_17": {name: "Materialität 1", values: ["Holz", "Glas", "Metall", "Stoff", "Papier/Karton", "Plexi", "Plastik", "Keine Angabe", "Porzelan", "Steinzeug/Ton", "Stein", "Samt", "Rattan", "Chrom", "Aluminium", "Leder", "Emaille", "Kunstleder", "MDF", "Wolle", "Bast", "Kelko", "Plüsch", "Messing"]},
          "custom_18": {name: "Funktionstüchtig",values: ["Funktioniert","Funktioniert nicht", "Ungeprüft"]},
          "custom_19": {name: "Materialität 2", values: ["Holz", "Glas", "Metall", "Stoff", "Papier/Karton", "Plexi", "Plastik", "Keine Angabe", "Porzelan", "Steinzeug/Ton", "Stein", "Samt", "Rattan", "Chrom", "Aluminium", "Leder", "Emaille", "Kunstleder", "MDF", "Wolle", "Bast", "Kelko", "Plüsch", "Messing"]},
        } */
      }
    },
    watch: {
      propId: {
        immediate: true,
        async handler(newPropId) {
          await this.fetchPropDetails(newPropId);
          this.$helpers.setWindowTitle(this.$t('Prop'), this.prop.name[this.$i18n.locale])
        },
      },
    },
    methods: {
      async fetchPropDetails(propId) {
        if(!propId) {  // propId === undefined
          // propId is not set
          //this.fetchLastCategory();  // ???
          this.loading = false;
          this.prop = null;
          this.files = [];
          return;
        }
        this.loading = true;
        try {
          const response = await axios.get(`props/${propId}/data.json`);
          this.prop = response.data.equipment_data;
          this.files = response.data.files;
          // update URL so the breadcrumps in parent get supdated.
          // important for searches and favorites (?)
          let targetPath = `/db/${this.prop.idPath.join('/')}/${propId}.html`;
          // Only replace route if it changed
          if (JSON.stringify(this.$route.path) !== JSON.stringify(targetPath)) {
            this.$router.replace(
              {
                path: targetPath,
                query: this.$route.query
              }
            );
          }
        } catch (error) {
          // console.error('Failed to load prop details:', error);
          this.prop = null;
          this.files = [];
        } finally {
          this.loading = false;
        }
      },
      scrollToLocal(refName) {
        this.$refs[refName].scrollIntoView({ block: 'nearest', behavior: 'auto' })
      },
      hasTags(propTags, tags) {
        // Filter through tags and return their names in the current locale
        return tags
          .filter(tagKey => propTags[tagKey]) // Ensure the key exists in propTags
          .map(tagKey => propTags[tagKey].name[this.$i18n.locale]) // Get the name in the current locale
          .filter(Boolean); // Remove undefined or null values
      },
      rentmanFieldId(prop, fieldName, index) {
        return Object.keys(prop.custom).filter(
          key => prop.custom[key].value[this.$i18n.locale] && prop.custom[key].value[this.$i18n.locale].length > 0
        )[index] === fieldName
      }
    },
    computed: {
      mainDomain() {
        return new URL(window.location.origin).origin;
      },
      dimensionsString() {
        const labels = {
          length: { de: "L", en: "L", fr: "L" },
          width: { de: "B", en: "W", fr: "l" },
          height: { de: "H", en: "H", fr: "H" }
        };
        // Array to hold each dimension string if it exists
        const dimensions = [];
        if (this.prop.length > 0) {
          dimensions.push(`<span>${labels.length[this.$i18n.locale]} ${this.prop.length}</span>`);
        }
        if (this.prop.width > 0) {
          dimensions.push(`<span>${labels.width[this.$i18n.locale]} ${this.prop.width}</span>`);
        }
        if (this.prop.height > 0) {
          dimensions.push(`<span>${labels.height[this.$i18n.locale]} ${this.prop.height}</span>`);
        }
        // Join dimensions with &times; symbol and return as a single string
        return dimensions.join(" <span class='grey--text px-1'>&times;</span> ");
      }
    },
    created() {
      this.originalTitle = document.title;
    },
    beforeDestroy() {
      // Reset the title to the original title when the component is destroyed
      this.$helpers.setWindowTitle('overwrite', this.originalTitle)
    },
  };
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}
</style>