<template>
  <div class="">
    <v-chip
      v-for="(tag) in Object.entries(tags).slice(0, 15)"
      :key="tag[0]"
      class="capitalize black--text mr-2"
      small
      :color="tag[1].color"
    >
      <!-- {{ index }}.  -->
      {{ tag[1].tag_category[$i18n.locale] }}:
      {{ tag[1].name[$i18n.locale] }}. 
      <!-- {{ tag.name[$i18n.locale] }} ({{ tag.numIds }}) -->
    </v-chip>
  </div>
</template>

<script>
import axios from 'axios';

  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
    },
    components: {
    },
    data() {
      return {
        tags: {}
      }
    },
    watch: {
    },
    methods: {
    },
    computed: {
    },
    async created() {
      try {
        const response = await axios.get(`tags.json`);
        //// console.log("this.categories:::")
        this.tags = response.data;
        //// console.log(this.category)
      } catch (error) {
        // console.error('Failed to load prop details:', error);
        this.tags = {};
      }
    }
  };
</script>
