<template>
  <div class="overline grey--text">
    <span v-for="(cat, catIndex) in categories" :key="catIndex">
      <!-- show cat icons  -->
      <!-- disable somehow link when .. no children-cats? no props?  -->
      <span v-if="categoriesFlat[cat] && categoriesFlat[cat].name">
        <!-- v-if="$vuetify.breakpoint.mdAndUp" -->
        <router-link
          :to="`/db/${categories.slice(0, catIndex + 1).join('/')}`"
          class="pointer"
          :class="catIndex != categories.length - 1 ? 'no-underline' : 'secondary--text'"
        >
          <v-icon x-small :class="catIndex != categories.length - 1 ? 'primary--text' : 'secondary--text'"> <!-- :class="!Object.keys(item.children).length ? 'secondary--text' : 'primary--text'"> -->
            mdi-{{ categoriesFlat[cat].icon && categoriesFlat[cat].icon !== '-' ? categoriesFlat[cat].icon : "rhombus-medium" }}
          </v-icon>
          {{ categoriesFlat[cat].name[$i18n.locale] }}
          <!-- NOT IN _categories-raw.json! -->
          <!-- ({{ categoriesFlat[cat].amountOfProps }} / {{ categoriesFlat[cat].children ? categoriesFlat[cat].children.length : 0 }}) -->
        </router-link>
        <!-- 
          dont use chips.
          the emitting shall happen either way.
          but i want the mobile-category to open / user to scroll to category depending on which category you clicked
          maybe clicking on crumb only sets the URL straight up and the category component does everything by themselfes?
         -->
       <!--  <v-chip
          v-else
          small
          color="transparent"
          :class="catIndex != categories.length - 1 ? 'primary--text' : 'secondary--text'"
          @click="$emit('openCategoryChooser')"
        >
          <v-icon class="mr-2" x-small :class="catIndex != categories.length - 1 ? 'primary--text' : 'secondary--text'">
            mdi-{{ categoriesFlat[cat].icon && categoriesFlat[cat].icon !== '-' ? categoriesFlat[cat].icon : "rhombus-medium" }}
          </v-icon>
          {{ $helpers.ellipsis(categoriesFlat[cat].name[$i18n.locale], 15, '…') }}
        </v-chip> -->
      </span>
      <span v-else title="category does not exist">{{ cat }}</span>
      <span v-if="catIndex != categories.length - 1">
        <v-icon small class="grey--text">mdi-chevron-right</v-icon>
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      categories: Array,
      categoriesFlat: Object,
    },
    components: {
    },
    data() {
      return {
      }
    },
    watch: {
    },
    methods: {
    },
    computed: {
    },
    created() {
    }
  };
</script>
