<template>
  <v-progress-circular indeterminate size="44" :width="hideLoading ? 0 : 3" color="primary" class="ma-a fadeInRamp" :class="classes">
    <v-carousel cycle continuous height="40" hide-delimiter-background hide-delimiters :interval="interval" hide-arrows
      :show-arrows="false" touchless>
      <v-carousel-item v-for="(slide, i) in loadingSlidesIcons" :key="i">
        <v-sheet height="100%" color="transparent">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-icon class="secondary--text">{{ slide }}</v-icon>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-progress-circular>
</template>

<script>
export default {
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    classes: String,
    hideLoading: Boolean,
    interval: Number,
  },
  components: {
  },
  data() {
    return {
      loadingSlidesIcons: [
        'mdi-briefcase',
        'mdi-sofa-single',
        'mdi-wardrobe',
        'mdi-desktop-classic',
        'mdi-desk-lamp',
        'mdi-format-paint',
        'mdi-hammer-wrench',
        'mdi-heart',
      ],
    }
  },
  watch: {
  },
  methods: {
  },
  computed: {
  },
  created() {
    if(!this.interval) {
      this.interval === 444;
    }
  }
};
</script>
