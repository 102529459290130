<template>
  <v-tooltip :color="$vuetify.theme.dark ? '#303030' : '#efefef'" :open-on-click="$vuetify.breakpoint.smAndDown" :bottom="!position || position === 'bottom'" :right="position === 'right'" :left="position === 'left'" :top="position === 'top'">
    <template v-slot:activator="{ on, attrs }">
        <v-icon class="hover" :style="opacity ? `opacity: ${opacity} !important;` : `opacity: .25 !important;`" :class="iconClasses ? iconClasses : 'grey--text ml-1'" v-bind="attrs" v-on="on"
          :x-small="x_small"
          :small="small"
          :medium="medium"
          :large="large"
          :x-large="x_large"
        >
          mdi-{{icon ? icon : 'information'}}
        </v-icon>
    </template>
    <div style="max-width:275px" :class="$vuetify.theme.dark ? '' : 'black--text'">
      <b>{{title}}</b>
      <br v-if="title">
      <span v-html="text" :class="title ? 'grey--text' : ''"></span>
    </div>
  </v-tooltip>
</template>

<script>
  export default {
    props: [
      'title', 'text', 'icon', 'iconClasses', 'position', 'opacity',
      'x_small', 'small', 'medium', 'large', 'x_large'
    ],
  }
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}
</style>