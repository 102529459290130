<template>
  <v-tooltip :bottom="!position || position === 'bottom'" :right="position === 'right'" :left="position === 'left'" :top="position === 'top'" color="#303030">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs" v-on="on"
        :class="buttonClasses"
        :small="$vuetify.breakpoint.mdAndUp"
        :color="buttonColor ? buttonColor : 'primary'"
        @click="share(text)"
        :disabled="disabled"
      >
        <v-icon
          class="hover"
          :style="opacity ? `opacity: ${opacity} !important;` : ``"
          :class="iconClasses ? iconClasses : ''"
          :small="$vuetify.breakpoint.mdAndUp"
          v-bind="attrs"
          v-on="on"
        >mdi-{{icon ? icon : 'share-variant'}}
        </v-icon>
      </v-btn>
    </template>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: ['text', 'icon', 'toastedInfo', 'iconClasses', 'buttonClasses', 'buttonColor', 'tooltip', 'position', 'opacity', 'disabled'],
    data () {
      return {
      }
    },
    methods: {
      async share(text) {
        console.log(text)
        
        if(navigator.share) {
          await navigator.share({text: text}).then(() => {
            this.$toasted.global.info({msg:`Thanks 💯 for sharing!`});
          }).catch(err => {
            console.log(err)
            //this.$toasted.global.error({msg:err});
          });
        } else {
          this.$helpers.copyClipBoard(text, this.toastedInfo ? this.toastedInfo : 'Link for sharing in messengers')
        }
      }
    },
  }
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}
</style>