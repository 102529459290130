<template>
  <div>
    <v-text-field
      v-if="showSearch"
      v-model="search"
      class="ml-2"
      :placeholder="
        `${$t('Search categories in')} ${$i18n.availableLocales.join(', ').toUpperCase()}`
      "
      filled
      dense
      hide-details
      clearable
      clear-icon="mdi-backspace"
      :prepend-icon="openAll ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal'"
      @click:prepend="openAll = !openAll; $refs.tree.updateAll(openAll)"
      :append-icon="search ? '' : 'mdi-filter-variant'"
      @click:clear="search = ''; $refs.tree.updateAll(false)"
      @keyup="onKeyUp"
      @keyup.enter="triggerSearch"
    ></v-text-field>
<!-- 
    testOpen: <code>{{ testOpen }}</code><br>
    openCategories: <code>{{ openCategories }}</code>

    <v-treeview
      ref="tree"
      class="superdense mt-4"
      :items="categories"
      item-key="id"

      expand-icon="mdi-chevron-down"
      activatable
      hoverable
      dense
      rounded

      :open="testOpen"
    /> -->

    <v-skeleton-loader
      v-if="!Object.keys(categories).length"
      class="dense-skeleton my-4 ml-4"
      type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
      <!-- type="paragraph, sentences, text, text, sentences, paragraph@3" -->

    <v-treeview
      v-else
      ref="tree"
      class="mt-4"
      :class="$vuetify.breakpoint.mdAndUp ? 'superdense' : ''"
      :items="categories"
      :search="search"
      :filter="customFilter"
      item-key="id"
      expand-icon="mdi-chevron-down"
      activatable
      hoverable
      dense
      rounded
      open-on-click
      return-object
      :open-all="openAll"
      @update:active="clickOnCategory($event[0] ? $event[0].idPath : null)"
    >
    <!-- 
      :open="openCategories" -->
      <!-- @update:open="openCollection($event[0] ? $event[0].idPath : null)" -->
      <!-- :open="categoriesSelected" -->
      <template v-slot:prepend="{ item }">
        <v-icon :class="!Object.keys(item.children).length ? 'secondary--text' : 'primary--text'">
          mdi-{{ item.icon && item.icon !== '-' ? item.icon : "rhombus-medium" }}
        </v-icon>
      </template>

      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:label="{ item, active }">
        <!-- this is only when category is already 'selected' and the @update:active is not returning an object -->
        <div
          @click="active ? [clickOnCategory(item.idPath), $event.stopPropagation()] : null"
          style="text-wrap:auto;"
        >
          {{ item.name[$i18n.locale] }}
          <!-- / {{ item.id }} / {{ typeof item.id }} / {{ openCategories.includes(item.id) }} -->
          <v-chip dense x-small class="px-1" v-if="item.children.length == 0">{{  item.amountOfProps.toLocaleString('en').replace(',', "'") }}</v-chip>
          <v-chip dense x-small class="px-1" v-else color="primary">{{  item.amountOfProps.toLocaleString('en').replace(',', "'") }}</v-chip>
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
export default {
  props: {
    auth: Boolean,
    edit: Boolean,
    user: Object,
    settings: Object,
    categories: Array,
    openCategories: Array,
    showSearch: Boolean,
    // categoriesSelected: Array,
  },
  components: {
    // IconSelector,
    // TextfieldAutotranslate,
    // eslint-disable-next-line vue/no-unused-components
    // ListItem,
  },
  data() {
    return {
      search: "",
      testOpen: [50,58,136],
      openAll: false,
      searchDebounceTimer: null, // To store the debounce timer reference
      // openedNodes: [50, 176, 58],  // hm does not work
    };
  },
  async created() {
  },

  beforeDestroy() {
  },

  computed: {
  },

  methods: {
    /* openCollection(id) {  // , parentId){
      // Gets all currently opened folders
      if(id) {
        // console.log('Clicked openCollection. id: ', id)  // , 'parentId: ', parentId
      } else {
        // Sadly, no object is returned from $event.
        console.log('Clicked openCollection to close.')
      }
    }, */

    clickOnCategory(idPath){  // , parentId
      // console.log('Clicked clickOnCategory: ', id, parentId);
      /* if(id.startsWith("add-")) {
        this.setupNewCategory(parentId)
      } else { */
        // console.log('clickedCategory + emit: "', idPath)

        // this.$router.push({path: `/db/${idPath.join('/')}`})

        let targetPath = `/db/${idPath.join('/')}`;
        // Only replace route if it changed
        if (JSON.stringify(this.$route.path) !== JSON.stringify(targetPath)) {
          this.$router.replace(
            {
              path: targetPath,
              query: this.$route.query
            }
          );
        }


        this.$emit('clickedCategory', true)  // SHOULD THIS BE THE COMPLETE PARENT PATH?

      /* } */
      return;
    },

    fuzzySubstringMatch(text, search) {  // , threshold = 3
      // MAKE IT SEEM TO WORK
      return text.includes(search);
    },

    /* fuzzySubstringMatch(text, search, threshold = 3) {
      const textLength = text.length;
      const searchLength = search.length;

      // Iterate over each possible starting position in the text
      for (let i = 0; i <= textLength - searchLength; i++) {
        // Extract a substring of the text with the same length as the search term
        const substring = text.slice(i, i + searchLength);

        // Calculate the Levenshtein distance between the substring and the search term
        const distance = levenshteinDistance(substring, search);

        // If the distance is within the threshold, return true (we found a match)
        if (distance <= threshold) {
          return true;
        }
      }

      // If no match was found within the threshold, return false
      return false;
    }, */

    fuzzyMatchOLD2(text, search, threshold = 30) {
      // Calculate the Levenshtein distance between the search and text
      const distance = this.$helpers.levenshteinDistance(text, search);

      // Return true if the distance is within the allowed threshold
      return distance <= threshold;
    },

    // Simple fuzzy match: check if each character in the search query is in the text (in order)
    fuzzyMatchOLD(text, search) {
      // This kinda finds anagrams of all the languages
      let searchIndex = 0;
      for (let i = 0; i < text.length; i++) {
        if (text[i] === search[searchIndex]) {
          searchIndex++;
        }
        if (searchIndex === search.length) {
          return true; // We found all characters of search in the text
        }
      }
      return false; // Not all search characters found
    },

    customFilterOLD(item, search) {  // , textKey
      // Start searching only when there are at least 2 characters
      if (!search || search.length < 2) return true;

      // Combine all `name` values into a single string (joining the values from the `name` object)
      let combinedName = Object.values(item.name).join('');

      // Normalize both the combined name and the search term
      const normalizedText = this.$helpers.normalizeText(combinedName);
      const normalizedSearch = this.$helpers.normalizeText(search);

      // Use simple fuzzy matching for the search term
      return this.fuzzyMatch(normalizedText, normalizedSearch);
    },

    customFilter(item, search) {  // , textKey
      // Start searching only when there are at least 2 characters
      if (!search || search.length < 2) return true;

      // Combine all `name` values into a single string (joining the values from the `name` object)
      let combinedName = Object.values(item.name).join('');

      // Normalize both the combined name and the search term
      const normalizedText = this.$helpers.normalizeText(combinedName);
      const normalizedSearch = this.$helpers.normalizeText(search);

      // Use fuzzy substring matching with a threshold of 2
      return this.fuzzySubstringMatch(normalizedText, normalizedSearch, 2); // Allow up to 2 character differences
    },

    // Trigger the search (manually or on debounce)
    triggerSearch() {
      // Expand all nodes before searching
      // Manually trigger reactivity for search by setting it to itself
    // eslint-disable-next-line no-self-assign
      this.search = this.search; // This is mainly to trigger a reactivity update in Vue
    },

    // Key up handler to debounce the search
    onKeyUp() {
      clearTimeout(this.searchDebounceTimer); // Clear the previous timer
      this.openAll = true;  // expand the list if anything is searched
      this.$refs.tree.updateAll(true)  // trigger re-render
      // Set a new timer for 300ms debounce
      this.searchDebounceTimer = setTimeout(() => {
        this.triggerSearch();
      }, 300);
    },
  },
  mounted() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    /* categories: function(oldsearch, newsearch) {
      this.nestedCategories = this.createNestedMenuStructure(this.categories);
    }, */
  },

};
</script>

<style>
  .superdense .v-treeview-node .v-treeview-node__root {
    margin-top:0 !important;
    margin-bottom:0 !important;
  }
  .dense-skeleton.v-skeleton-loader > div {
    background-color: transparent !important;
  } 
  .dense-skeleton.v-skeleton-loader > div div:first-of-type {
    height: 1.5em;
    width: 1.5em;
  }
  .dense-skeleton.v-skeleton-loader .v-skeleton-loader__list-item-avatar {
    height: 2em;
  }
</style>