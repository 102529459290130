<template>
  <div>
    <div class="d-flex flex-column" style="height: calc(100vh - 100px);">
    <!-- Header -->
    <div class="flex-grow-0">
        <!-- Header content here -->
        Header
    </div>

    <!-- Main Content -->
    <div class="orange flex-grow-1 overflow-auto">
    <div class="green overflow-auto" style="width:25%; float:left;">
        <!-- Main content here -->
        Main Content

        <hr>
        HELPERS SKELETON LOADER
        <hr>
        button@2: <v-skeleton-loader type="button@2"></v-skeleton-loader>
        <br>
        heading: <v-skeleton-loader type="heading"></v-skeleton-loader>
        <br>
        paragraph: <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        <br>
        avatar: <v-skeleton-loader type="avatar"></v-skeleton-loader>
        <br>
        button: <v-skeleton-loader type="button"></v-skeleton-loader>
        <br>
        image: <v-skeleton-loader type="image"></v-skeleton-loader>
        <br>
        card-heading: <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        <br>
        list-item-avatar: <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
        <br>
        list-item: <v-skeleton-loader type="list-item"></v-skeleton-loader>
        <br>
        chip: <v-skeleton-loader type="chip"></v-skeleton-loader>
        <br>
        date-picker-options: <v-skeleton-loader type="date-picker-options"></v-skeleton-loader>
        <br>
        date-picker-days: <v-skeleton-loader type="date-picker-days"></v-skeleton-loader>
        <br>
        card-heading: <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        <br>
        divider: <v-skeleton-loader type="divider"></v-skeleton-loader>
        <br>
        actions: <v-skeleton-loader type="actions"></v-skeleton-loader>
        <br>
        avatar@2: <v-skeleton-loader type="avatar@2"></v-skeleton-loader>
        <br>
        avatar@28: <v-skeleton-loader type="avatar@28"></v-skeleton-loader>
        <br>
        text: <v-skeleton-loader type="text"></v-skeleton-loader>
        <br>
        text@2: <v-skeleton-loader type="text@2"></v-skeleton-loader>
        <br>
        text@3: <v-skeleton-loader type="text@3"></v-skeleton-loader>
        <br>
        sentences: <v-skeleton-loader type="sentences"></v-skeleton-loader>
        <br>
        table-heading: <v-skeleton-loader type="table-heading"></v-skeleton-loader>
        <br>
        table-thead: <v-skeleton-loader type="table-thead"></v-skeleton-loader>
        <br>
        table-tbody: <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        <br>
        table-tfoot: <v-skeleton-loader type="table-tfoot"></v-skeleton-loader>
        <br>
        heading@6: <v-skeleton-loader type="heading@6"></v-skeleton-loader>
        <br>
        table-row-divider@6: <v-skeleton-loader type="table-row-divider@6"></v-skeleton-loader>
        <br>
        table-row: <v-skeleton-loader type="table-row"></v-skeleton-loader>
        <br>
        table-cell@6: <v-skeleton-loader type="table-cell@6"></v-skeleton-loader>
        <br>
    </div><!-- 
     --><div class="blue overflow-auto" style="width:75%">
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
      B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>B<br>
    </div>
    </div>

    <!-- Footer -->
    <div class="flex-grow-0" style="clear:both">
      <!-- Footer content here -->
      Footer
    </div>
  </div>

    <!-- 

     -->
  </div>
</template>

<script>
export default {
  name: 'Test',

  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

