<template>
  <div>
    <v-dialog
      v-model="dialog"
      :width="width"
      :persistent="persistent"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card>
        <v-toolbar
          :class="titleClasses ? titleClasses : 'primary'"
        >
          <v-app-bar-nav-icon v-if="icon" style="color:inherit">
            <v-icon large style="color:inherit">mdi-{{ icon }}</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title class="pa-0">{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text :class="text && text.length ? 'pt-4' : 'pa-0'" v-html="text" style="height:100%">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions :style="$vuetify.breakpoint.xsOnly ? 'display:block;' : ''">
          <template v-for="(btn, index) in buttons">
            <v-spacer v-if="btn.name === 'spacer'" :key="'spacer-' + index" />
            <v-btn
              v-else
              :key="'btn-' + index"
              :icon="!btn.name"
              :color="btn.color && btn.name ? btn.color : index === buttons.length - 1 && btn.name ? 'primary' : ''"
              @click="dialog = false; $emit('clickBtn' + index)"
              :block="$vuetify.breakpoint.xsOnly"
              :class="[btn.name ? '' : btn.color, $vuetify.breakpoint.xsOnly ? 'ma-0 mb-4' : '', !$vuetify.theme.dark && btn.color ? 'white--text' : '']"
            >
              <v-icon small v-if="btn.icon" :class="btn.name ? 'mr-1' : ''">
                mdi-{{ btn.icon }}
              </v-icon>
              {{ btn.name }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      trigger: Boolean,
      width: Number,
      persistent: Boolean,
      buttons: Array,
      icon: String,
      title: String,
      text: String,
      titleClasses: String,
    },
    components: {
    },
    data() {
      return {
        dialog: false,
      }
    },
    watch: {
      trigger: function (data) {
        // console.log(data)
        this.dialog = data;
      },
      dialog: function (data) {
        this.$emit('dialog', data);
      },
    },
    methods: {
    },
    computed: {
    },
    async created() {
    }
  };
</script>
