<template>
  <div class="">
    <!-- v-if="$vuetify.breakpoint.smAndDown || $device.ios"  -->
    <v-btn icon :small="small" class="primary white--text" @click="dialog = true">
      <v-icon :small="small">mdi-qrcode-scan</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      :width="600"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card>
        <v-toolbar
          class="primary white--text"
        >
          <v-app-bar-nav-icon style="color:inherit">
            <v-icon large style="color:inherit">mdi-qrcode-scan</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title class="pa-0">
            {{ $t('Scan QR Code') }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text :style="$vuetify.breakpoint.smAndDown ? 'height:100%' : 'height:600px'" class="pa-0">
          <qrcode-stream
            v-if="dialog"
            ref="qrcodeStream"
            @click="restartCamera()"
            @detect="onDetect"
            @init="onInit"
            :camera="cameraMode"
            :key="qrcodeStreamKey"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'mirr-x'"
          > <!--  class="mb-4" -->
            <v-container
              v-if="loadingCamera || errorCamera"
              fill-height
              fluid 
              @click="resetCamera()"
            >
              <v-row align="center"
                justify="center">
                <v-col class="text-center">
                  <v-progress-circular
                    v-if="loadingCamera"
                    indeterminate
                    size="44"
                    width="3"
                    color="primary"
                    class="ma-a"
                  ><v-icon>mdi-camera</v-icon></v-progress-circular>

                  <div
                    v-if="errorCamera" class="inlineBlock rounded pa-3 error white--text bold"
                    :class="$vuetify.breakpoint.smAndDown ? '' : 'mirr-x'"
                    style="overflow-wrap: anywhere;"
                  >
                    <v-icon class="white--text" small>mdi-alert</v-icon>
                    {{errorCamera}}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </qrcode-stream>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions :style="$vuetify.breakpoint.xsOnly ? 'display:block;' : ''">
          <v-spacer></v-spacer>
          <!-- :block="$vuetify.breakpoint.xsOnly && !cameraFlashSupported" -->
          <v-btn
            @click="dialog = false; errorCamera = ''"
            :class="$vuetify.theme.dark ? '' : ''"
            >
            <v-icon small>
              mdi-close
            </v-icon>
            {{ $t('Close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon class="primary white--text" v-if="cameraFlashSupported" @click="toggleFlashHandler()">
            <v-icon>mdi-{{cameraFlashOn ? 'flashlight-off' : 'flashlight'}}</v-icon>
          </v-btn>
          <!-- <v-btn icon class="primary white--text">
            <v-icon>mdi-{{cameraFlashOn ? 'flashlight-off' : 'flashlight'}}</v-icon>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'  // QrcodeDropZone, QrcodeCapture

  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      small: Boolean,
    },
    components: {
      QrcodeStream,
    },
    data() {
      return {
        dialog: false,
        error: '',
        errorCamera: '',
        cameraMode: 'auto',
        accessCode: '',
        qrCode: false,
        loadingCamera: false,
        qrcodeStreamKey: '',
        cameraFlashOn: false,
      }
    },
    watch: {
      dialog() {
        if(!this.dialog) {
          console.log("closed dialog")
          this.toggleFlashHandler(false);
        }
      },
    },
    methods: {
      async onDetect (promise) {
        try {
          const {
            // imageData,    // raw image data of image/frame (Uint8ClampedArray)
            content,      // decoded String
            location      // QR code coordinates
          } = await promise

          // If is number, assume QR/rentman code
          // console.log("onDetect:", imageData, content, location)
          if(/^[0-9]+$/.test(content)) {
            console.log("onDetect:", content)
            console.log(location)
            this.$emit('detected', content);
            this.dialog = false;

          } else if(content.length) {
            console.log('Something else detected', content);
            console.log(location)
            this.errorCamera = `${this.$t('This is not a valid ça tourne QR code')}: '${content}'`
          }
        } catch (error) {
          console.log("onDetect:", error);
          this.errorCamera = error;
        }
      },

      async onInit (promise) {
        // show loading indicator
        this.loadingCamera = true;
        try {
          //const { capabilities } = 
          await promise
          //console.log("onInit", capabilities);
          // successfully initialized
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            console.log("User denied camera access permisson", error);
            this.errorCamera = "Please allow for camera access.";
          } else if (error.name === 'NotFoundError') {
            console.log("No suitable camera device installed", error);
            this.errorCamera = "There is no suitable camera device installed";
          } else if (error.name === 'NotSupportedError') {
            console.log("Page is not served over HTTPS (or localhost)", error);
            this.errorCamera = "Page is not served over HTTPS (or localhost)";
          } else if (error.name === 'NotReadableError') {
            console.log("Maybe camera is already in use", error);
            this.errorCamera = "Your camera is already in use";
          } else if (error.name === 'OverconstrainedError') {
            console.log("Did you request the front camera although there is none?", error);
            this.errorCamera = "Did you request the front camera although there is none?";
          } else if (error.name === 'StreamApiNotSupportedError') {
            console.log("Browser seems to be lacking features", error);
            this.errorCamera = "Your browser does not support this camera feature";
          }
        } finally {
          //console.log("hide loading indicator");
          this.loadingCamera = false;
        }
      },

      resetCamera() {
        this.cameraMode = 'rear';
        this.errorCamera = '',
        this.cameraMode = 'auto';
        this.qrcodeStreamKey = this.$helpers.createUid();
      },

      // Basically, autofocus (cycle camera to have it autofocus on the first thing it sees)
      restartCamera() {
        this.$refs.qrcodeStream.stop(); // Stop the camera
        this.$nextTick(() => {
          this.$refs.qrcodeStream.start(); // Restart the camera
        });
      },

      toggleFlashHandler(mode) {
        try {
          const videoTrack = this.$refs.qrcodeStream?.getVideoTrack();
          if (videoTrack) {
            this.toggleFlash(videoTrack, mode);
          } else {
            this.cameraFlashOn = false;
            console.error("Video track not available.");
          }
        } catch (error) {
            this.cameraFlashOn = false;
            return false
        }
      },

      async toggleFlash(videoTrack, mode) {
        const currentTorchState = videoTrack.getSettings().torch || false;
        const newTorchState = mode === undefined ? !currentTorchState : mode; // Toggle if mode is undefined
        await videoTrack.applyConstraints({ advanced: [{ torch: newTorchState }] });
        this.cameraFlashOn = newTorchState;
      },
    },

    computed: {
      cameraFlashSupported() {
        try {
          const videoTrack = this.$refs.qrcodeStream?.getVideoTrack();
          if(videoTrack) {
            return videoTrack?.getCapabilities()?.torch === true;
          } else {
            return false
          }
        } catch (error) {
            return false
        }
      }
    },
    async created() {
    }
  };
</script>
