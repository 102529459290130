<template>
  <div>
    <!-- !!query && query.length > 0 <code>{{ !!query && query.length > 0 }}</code><br>
    resultsFound == 0 <code>{{ resultsFound == 0 }}</code><br>
    !!query && query.length > 0 && resultsFound == 0 <code>{{ !!query && query.length > 0 && resultsFound == 0 }}</code> -->
    <v-text-field
      class="search"
      ref="search"
      filled
      v-model="query"
      @input="delaySearch()"
      @keyup.enter="performSearch({enter: true})"
      :placeholder="
        `${$t('Search props in')} ${onlyCurrentLanguage ? $t(`locale.${onlyCurrentLanguage}`) : $i18n.availableLocales.join(', ').toUpperCase()}`
      "
      clearable
      :dense="$vuetify.breakpoint.xsOnly"
      clear-icon="mdi-backspace"
      :loading="loading"
      :error-messages="error"
      :hide-details="error.length == 0"
      :suffix="(!!query && query.length) || resultsFound ? $helpers.plural(resultsFound, [$t('result'), $t('results')]) : ''"
      :error="error.length > 0 || (!!query && query.length > 0 && resultsFound == 0)"
    >
      <template v-slot:prepend v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
          icon
          class="white--text primary nudge-y--50"
          @click="$emit('openCategory')"
        >
          <v-icon class="">
            mdi-file-tree
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:append>
        <div class="searchCheckbox mr-2 nudge-y--50" v-if="$vuetify.breakpoint.mdAndUp">
          <!-- <v-counter v-bind="props" :value="title.trim().split(' ').length"></v-counter> -->
          <!-- {{ $t('Exact search') }} -->
          <v-checkbox
            class="ma-0 pa-0"
            :label="$t('Exact search')"
            v-model="numTypos"
            @change="performSearch()"
            :true-value="0"
            :false-value="2"
            hide-details
            dense
          ></v-checkbox>

          <v-checkbox
            class="ma-0 pa-0"
            :label="`${$t('Only search in')} ${$t(`locale.${$i18n.locale}`) }`"
            v-model="onlyCurrentLanguageCheckbox"
            @change="$emit('onlyCurrentLanguage', onlyCurrentLanguage), performSearch()"
            hide-details
            dense
          ></v-checkbox>
        </div>
        <div class="searchQrCode nudge-y--12">
          <QrReader :small="true" @detected="query= $event; performSearch({enter: true});"></QrReader>
        </div>
      </template>
    </v-text-field>

    <!-- numTypos: {{ numTypos }} -->
    <!-- numTyposCheckbox: {{ numTyposCheckbox }} -->
    <!-- numTypos = 2-numTyposCheckbox*2;  -->
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-checkbox
        class="inlineBlock mr-4"
        :class="$vuetify.breakpoint.xsOnly ? 'mt-0 pt-0' : ''"
        :label="$t('Exact search')"
        v-model="numTypos"
        @change="performSearch()"
        :true-value="0"
        :false-value="2"
        hide-details
        dense
      ></v-checkbox>

      <v-checkbox
        class="inlineBlock"
        :class="$vuetify.breakpoint.xsOnly ? 'mt-0 pt-0' : ''"
        :label="`${$t('Only search in')} ${$t(`locale.${$i18n.locale}`) }`"
        v-model="onlyCurrentLanguageCheckbox"
        @change="$emit('onlyCurrentLanguage', onlyCurrentLanguage), performSearch()"
        hide-details
        dense
      ></v-checkbox>
    </div>

    <!-- <ul>
      <li v-for="result in results" :key="$helpers.createUid()+result.code">
        {{ result.id }}
        {{ result.name }}
      </li>
    </ul> -->
    <Tags v-if="false"></Tags>
  </div>
</template>
  
<script>
import axios from 'axios';
import Tags from "../components/Tags.vue"
import QrReader from "../components/QrReader.vue"

export default {
  props: {
    loadNextTrigger: String,
  },
  components: {
    Tags,
    QrReader,
  },
  data() {
    return {
      query: '',
      searchTimeout: null,
      perPage: 75,
      resultsFound: 0,
      results: [],
      loading: false,
      error: '',
      onlyCurrentLanguageCheckbox: false,
      // numTyposCheckbox: false,
      numTypos: 2,
    };
  },
  created() {
    // console.log(this.$route.query.q);
    // Get search term from reloading hard
    if(this.$route.query.q) {
      this.query = decodeURIComponent(this.$route.query.q);
      this.performSearch();
    }
  },
  watch: {
    loadNextTrigger() {
      this.loadNextPage();
    }
  },
  
  computed: {
    onlyCurrentLanguage() {
      return this.onlyCurrentLanguageCheckbox ? this.$i18n.locale : ''
    }
  },

  methods: {
    loadNextPage() {
      if(this.results.length < this.resultsFound) {
        this.performSearch({page:Math.ceil(this.results.length/this.perPage)+1})
      }
    },
    delaySearch() {
      // Clear the previous timeout if it exists
      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      // Set a new timeout for 400ms
      this.searchTimeout = setTimeout(() => {
        this.performSearch();
      }, 400);
    },

    // eslint-disable-next-line no-unused-vars
    async performSearch(settings={}) {
      // reset timeout that is maybe there from typing but skipped ahead by pressing enter
      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      let enter = settings.enter || false;
      let page = settings.page || 1;
      if(page === 1) this.results = [];
      this.loading = false;
      this.error = '';

      if (!this.query) {  // || (this.query.length <= 2 && !enter)) {
        let targetQuery = { ...this.$route.query }
        delete targetQuery.q;  // Remove the 'q' parameter if it exists
        delete targetQuery.a;  // Remove the 'a' parameter if it exists
          if(JSON.stringify(this.$route.query) !== JSON.stringify(targetQuery)) {
            this.$router.replace(
              {
                query: targetQuery
              }
            );
          }
        return;
      }

      this.loading = true;
      this.$emit('loading');

      let typos = this.numTypos

      // If search entirely by numbers (rentman_id or qrcodes_of_serial_numbers),
      // disable fuzzy search temporarely
      if(/^[0-9]+$/.test(this.query)) {
        typos = 0;
      }

      // Adjust the weights of the lang if onlyCurrentLanguage.length is not 0
      let queryByWeights = [
          {'name': 'rentman_code', 'weight': 5 },
          {'name': 'rentman_id', 'weight': 4 },
          {'name': 'name', 'weight': this.onlyCurrentLanguage.length ? 0 : 4 },         // ignore when only looking for current lang
          {'name': 'type', 'weight': 3 },
          {'name': 'description', 'weight': this.onlyCurrentLanguage.length ? 0 : 3 },  // ignore when only looking for current lang  // (rentman custom_4)
          {'name': 'tags', 'weight': this.onlyCurrentLanguage.length ? 0 : 2 },         // ignore when only looking for current lang
          {'name': 'tags_hash', 'weight': 1 },
          {'name': 'shop_description_long', 'weight': 1 },
          {'name': 'qrcodes', 'weight': 1 },
          {'name': 'qrcodes_of_serial_numbers', 'weight': 1 },
          {'name': 'name-de', 'weight': this.onlyCurrentLanguage === 'de' ? 4 : 0 },
          {'name': 'name-en', 'weight': this.onlyCurrentLanguage === 'en' ? 4 : 0 },
          {'name': 'name-fr', 'weight': this.onlyCurrentLanguage === 'fr' ? 4 : 0 },
          {'name': 'description-de', 'weight': this.onlyCurrentLanguage === 'de' ? 3 : 0 },
          {'name': 'description-en', 'weight': this.onlyCurrentLanguage === 'en' ? 3 : 0 },
          {'name': 'description-fr', 'weight': this.onlyCurrentLanguage === 'fr' ? 3 : 0 },
          {'name': 'tags-de', 'weight': this.onlyCurrentLanguage === 'de' ? 2 : 0 },
          {'name': 'tags-en', 'weight': this.onlyCurrentLanguage === 'en' ? 2 : 0 },
          {'name': 'tags-fr', 'weight': this.onlyCurrentLanguage === 'fr' ? 2 : 0 },
      ]

      // Filter out fields with weight === 0
      queryByWeights = queryByWeights.filter(field => field.weight > 0);

      try {
        const response = await axios.get('https://5okpd4x0tsuhqymlp-1.a1.typesense.net/collections/equipment/documents/search', {
          headers: {
            'X-TYPESENSE-API-KEY': 'DpumY7p9IqL1uFN1TaosYTd1lxPUajwj', // Use a search-only key for security
          },
          params: {
            q: this.query,
            query_by: queryByWeights.map(item => item.name).join(','),
            query_by_weights: queryByWeights.map(item => item.weight).join(','),
            num_typos: typos,
            per_page: this.perPage,
            page: page,
            sort_by: '_text_match:desc',
            split_join_tokens: true,   // Allows Typesense to match across different word orders
            drop_tokens_threshold: 0,  // Prevents ignoring unmatched tokens
          },
        });

        // Extract the "id" field from each search result ("id" in )
        let resultsArray = [];
        if(page === 1) {
          this.results = response.data.hits.map(hit => hit.document);
          resultsArray = response.data.hits.map(hit => hit.document.id);
        } else {
          this.results = [...this.results, ...response.data.hits.map(hit => hit.document)];
          resultsArray = [...this.$route.query.a.split(','), ...response.data.hits.map(hit => hit.document.id)];
        }
        this.resultsFound = response.data.found;
        this.$emit('resultsFound', this.resultsFound)
        
        // Display single perfect searched prop if enter pressed / QR scanned and id is a match
        if(enter) {
          // hide keyboard on mobile if enter is pressed
          if(this.$vuetify.breakpoint.smAndDown) {
            this.$refs["search"].blur();
          }

          const exactMatchById = response.data.hits.find(hit => hit.document.id === this.query);
          const exactMatchBySerialNumber = response.data.hits.find(hit => hit.document.qrcodes_of_serial_numbers.includes(this.query));
          if(exactMatchById || exactMatchBySerialNumber) {
            let exactMatch = exactMatchById || exactMatchBySerialNumber;
            let id = exactMatch.document.id;
            let idPath = exactMatch.document.idPath.split(',')
            console.log("exactMatchById", idPath);
            // console.log("exactMatchById", idPath);
            let targetPath = `/db/${idPath.join('/')}/${id}.html`;

            // Only replace route if it changed
            if (JSON.stringify(this.$route.path) !== targetPath) {
              // let oldQuery = JSON.parse(JSON.stringify(this.$route.query));
              this.$router.replace(
                {
                  path: targetPath,
                }
                );
            }
            return;
          }
        }
        if(this.resultsFound) {
          // Something found, yay!
          let targetQuery = { ...this.$route.query, q: encodeURIComponent(this.query), a: resultsArray.join(',') }
          if(JSON.stringify(this.$route.query) !== JSON.stringify(targetQuery)) {
            this.$router.replace(
              {
                path: this.$route.path,
                query: targetQuery
              }
            );
          }
        } else {
          // Nothing found
          let targetQuery = { ...this.$route.query, q: encodeURIComponent(this.query) };
          delete targetQuery.a;  // Remove the 'a' parameter if it exists
          if(JSON.stringify(this.$route.query) !== JSON.stringify(targetQuery)) {
            this.$router.replace(
              {
                path: this.$route.path,
                query: targetQuery
              }
            );
          }
        }
      } catch (error) {
        this.error = 'Error performing search';
        console.error(error);
      } finally {
        this.loading = false;
        this.$emit('loaded');
      }
    },
  },
};
</script>
  
<style >
/* Reposition label of checkbox */
/*  .v-input__control .v-input__slot .v-input__append-inner { */
/* .searchCheckbox label {
  filter: blur(5px) !important;
} */

.search .searchCheckbox .v-input--selection-controls label {
  position: initial !important;
  display: block !important;
  text-align: right;
}
.search .searchCheckbox .v-input__slot {
    flex-direction: row-reverse;
}
.search .searchCheckbox .v-input--selection-controls__input {
  margin:0 0 0 8px;
}
</style>
