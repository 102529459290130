<template>
    <div class="pa-2 bordered inline-block">
      <!-- <v-icon color="primary" class="mx-1">mdi-translate</v-icon> -->

      <!-- <select v-model="$i18n.locale" class="white--text transparentWhite rounded ">
        <option
          v-for="sLocale in $i18n.availableLocales"
          :key="`locale-${sLocale}`"
          :value="sLocale"
        >
          {{ $t(`locale.${sLocale}`) }}
        </option>
      </select> -->

      <v-menu v-if="$vuetify.breakpoint.smOnly" offset-y close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
          {{ $t(`locale.${$i18n.locale}`) }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in $i18n.availableLocales"
            :key="index"
            @click="$i18n.locale = item"
            :class="item === $i18n.locale ? 'primary' : ''"
          >
            <v-list-item-title class="uppercase pointer" >{{ $t(`locale.${item}`) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <!-- BUTTONS FOR ALL LANGUAGES -->
      <v-btn-toggle v-else borderless :class="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-1'"><!--  v-model="$i18n.locale" -->
        <v-btn
          v-for="(locale, index) in $i18n.availableLocales" :key="index"
          dense
          :small="$vuetify.breakpoint.mdAndUp"
          :color="locale == $i18n.locale ? 'primary white--text' : ''"
          @click="setLocale(locale)"
          class="uppercase px-0"
        >
          {{ locale }}
        </v-btn>
      </v-btn-toggle>
    </div>
  </template>
  
  <script>
    export default {
      props: {
        auth: Boolean,
        user: Object,
        settings: Object,
      },
      data() {
        return {
          langDropdownMenu:false
        }
      },
      methods: {
        setLocale(locale) {
          this.$i18n.locale = locale;
          if(this.auth) {
            this.updateUserField('guiLanguage', this.user.uid, locale)
          }
          this.$helpers.writeLocalStorage("guiLanguage", locale);

          // Dynamically update the `lang` attribute for auto-hyphenation
          document.documentElement.setAttribute("lang", locale);
        },
        updateUserField(fieldName, userId, value) {
          this.$store.dispatch('updateField', {'collection':'users', 'document':userId,'field':fieldName, 'data': value}).then(() => {
          }).catch(error => {
            console.log(error);
            console.error(error.message);
            this.$toasted.global.error({msg:error.message});
            return;
          });
        },
      },
      mounted() {
        let guiLanguage = this.$helpers.readLocalStorage("guiLanguage")
        if(this.auth) {
          // FIMXE: does not load on hard reload
          this.setLocale(this.user.guiLanguage);
        } else if(guiLanguage) {
          if(guiLanguage) {
            this.setLocale(guiLanguage);
          }
        }
      }
    }
  </script>
  